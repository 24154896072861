import { parseISO, isAfter } from 'date-fns';
import http from '../httpConfig';

const pharmacyDiscount = (config) => {
  const headers = config;
  const endpoint = '/user/epharma/user_api_integration_credential';

  const ePharma = {
    partner: 'EPHARMA',
    cliente: process.env.VUE_APP_EPHARMA_CLIENTE,
    plano: process.env.VUE_APP_EPHARMA_PLANO,
    token: null,
  };

  const getDiscountDueDate = () => new Promise((resolve, reject) => {
    const url = `${endpoint}?order[u.tokenExpirationDate]=DESC`;

    http.get(url, headers)
      .then((res) => {
        const { data } = res.data;

        if (data.length > 0) {
          const { credentialExpiresAt } = data[0];

          if (isAfter(parseISO(credentialExpiresAt), new Date(), { unit: 's' })) {
            resolve(credentialExpiresAt);
            return;
          }

          reject();
          return;
        }

        reject();
      })
      .catch((err) => {
        if (
          typeof err === 'object'
          && Object.prototype.hasOwnProperty.call(err, 'response')
          && Object.prototype.hasOwnProperty.call(err.response, 'data')
        ) {
          reject(err.response.data);

          return;
        }

        reject(err);
      });
  });

  const loginEPharma = () => new Promise((resolve, reject) => {
    const url = '/external/user_api_integration_partner/api_external_connection';

    const params = [
      { key: 'grant_type', value: 'password' },
      { key: 'client_id', value: process.env.VUE_APP_EPHARMA_CLIENT_ID },
      { key: 'client_secret', value: process.env.VUE_APP_EPHARMA_CLIENT_SECRET },
      { key: 'username', value: process.env.VUE_APP_EPHARMA_USERNAME },
      { key: 'password', value: process.env.VUE_APP_EPHARMA_PASSWORD },
    ];

    const bodyContent = params.reduce(
      (acc, item) => (
        acc === ''
          ? `${acc}${item.key}=${item.value}`
          : `${acc}&${item.key}=${item.value}`
      ),
      '',
    );

    const data = {
      'api_external_connection': {
        drconectaPartner: ePharma.partner,
        urn: '/oauth/token',
        bodyContent,
      },
    };

    http.post(url, data)
      .then((res) => resolve(res.data.content['access_token']))
      .catch((err) => {
        if (
          typeof err === 'object'
          && Object.prototype.hasOwnProperty.call(err, 'response')
          && Object.prototype.hasOwnProperty.call(err.response, 'data')
        ) {
          reject(err.response.data);

          return;
        }

        reject(err);
      });
  });

  const findNearbyPharmacies = (
    { latitude = null, longitude = null }, page = 0,
  ) => new Promise((resolve, reject) => {
    loginEPharma()
      .then((token) => {
        ePharma.token = token;

        const params = [
          { query: 'plano', value: ePharma.plano },
          { query: 'latitude', value: latitude },
          { query: 'longitude', value: longitude },
          { query: 'raio', value: 10 },
          { query: 'itemsPerPage', value: 10 },
          { query: 'page', value: page },
        ];

        const queryString = params.reduce((acc, item) => {
          if (item.value) {
            return acc === '?'
              ? `${acc}${item.query}=${item.value}`
              : `${acc}&${item.query}=${item.value}`;
          }

          return acc;
        }, '?');

        return {
          url: '/external/user_api_integration_partner/api_external_connection',
          data: {
            'api_external_connection': {
              drconectaPartner: ePharma.partner,
              urn: `/api/RedeCredenciada/Geo/Paginado${queryString}`,
              headers: [`Authorization: Bearer ${ePharma.token}`],
            },
          },
        };
      })
      .then((res) => http.post(res.url, res.data))
      .then((res) => {
        const pages = res.data.content['total_Pages'];
        const data = res.data.content.data.farmacias.map((item) => ({
          name: String(item.loja).toLowerCase(),
          address1: String(`${item.endereco}${item.complemento ? ` ${item.complemento}` : ''}, ${item.bairro || ''}`).toLowerCase(),
          address2: `CEP ${item.cep.trim().replace(/^([\d]{5})([\d]{3})?/g, '$1-$2')} - ${item.cidade.toLowerCase()}/${item.uf}`,
          distance: parseFloat(item.distancia.replace(',', '.'), 2).toFixed(2),
          coords: {
            lat: item.latitude.replace(',', '.'),
            long: item.longitude.replace(',', '.'),
          },
        }));

        resolve({ pages, data });
      })
      .catch((err) => reject(err));
  });

  return {
    getDiscountDueDate,
    findNearbyPharmacies,
  };
};

export default pharmacyDiscount;
