<template>
  <div>
    <transition-group name="fade">
      <template v-if="ui.step === 1">
        <article
          :key="'geo-location-pharmacy'"
          class="user-section view sm:!mr-0 sm:!ml-0"
        >
          <h1 class="text-secondary text-center !text-md">
            Informe seu endereço ou CEP e
            <br class="sm:hidden">
            encontre a farmácia mais próxima
          </h1>

          <div class="appointment-specialty__box-wrapper">
            <div class="select-list">
              <input
                v-model="address"
                type="text"
                placeholder="Endereço ou CEP"
                class="select-list__input select-list__input--center appointment-specialty__input"
              >

              <transition
                name="fade"
                mode="in-out"
                v-if="
                  autocompleteAddress.length > 0
                    && !autocompleteAddress.some((item) => item.address === address)
                "
              >
                <ul class="select-list__list appointment-specialty__autocomplete">
                  <li
                    v-for="(prediction, index) in autocompleteAddress"
                    :key="`address-${index}`"
                    class="select-list__option"
                    @click="setAddress(prediction)"
                  >
                    {{ prediction.address }}
                  </li>
                </ul>
              </transition>
            </div>

            <ui-button
              :icon="require('@/assets/img/icons/ico-gps.svg')"
              color="secondary"
              label="Usar minha localização atual"
              class="appointment-specialty__gps"
              @click="getGeolocation"
            />
          </div>
        </article>

        <div
          :key="'call-to-action-pharmacy'"
          class="w-full flex items-center justify-center"
        >
          <div
            class="w-full mb-8"
            style="max-width: 20rem"
          >
            <ui-button
              :disabled="!latitude || !longitude"
              color="primary"
              label="Buscar farmácias"
              class="appointment-specialty__gps w-64"
              @click="goToStep(2)"
            />
          </div>
        </div>
      </template>
    </transition-group>

    <transition name="fade">
      <template v-if="ui.step === 2">
        <article class="user-section view sm:!mr-0 sm:!ml-0 !mt-0 !pt-0">
          <header class="flex flex-row sm:flex-col items-center justify-between w-full pt-6">
            <div
              class="
                button button--blank
                cursor-pointer
                !h-3 !text-xs !lowercase sm:ml-4
                sm:!hidden
                mt-0
              "
              style="height: 0.75rem; width: 7rem;"
              @click="goToStep(1)"
            >
              &lt; Voltar
            </div>

            <h1 class="text-secondary flex flex-row items-center justify-center">
              <icon
                name="discountPharmacy"
                color="secondary"
                fill="secondary"
                class="h-16 mt-3 mr-3"
              />

              Desconto Farmácia
            </h1>

            <div class="w-52">
              <ui-button
                color="secondary"
                :disabled="false"
                label="Meu cartão de descontos"
                style="
                  height: 2rem;
                  padding: 0 0.75rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 0.75rem;
                "
                @click="ui.showCard = true"
              />
            </div>
          </header>

          <transition
            name="fade"
            mode="out-in"
          >
            <div
              v-if="ui.hasLoaded || (!ui.isLoading && pharmaciesList.length > 0)"
              class="flex flex-row flex-wrap items-center justify-between my-4"
            >
              <div
                v-for="(pharmacy, index) in pharmaciesList"
                :key="`pharmacy-${index}`"
                class="pharmacy-card"
              >
                <h3 class="pharmacy-card__title">
                  {{ pharmacy.name }}
                </h3>

                <p class="pharmacy-card__address">
                  {{ pharmacy.address1 }}
                </p>

                <p class="pharmacy-card__address">
                  {{ pharmacy.address2 }}
                </p>

                <div class="pharmacy-card__row">
                  <div class="pharmacy-card__distance">
                    <icon
                      name="map"
                      color="black"
                      class="mr-1"
                      style="height: 1.5rem"
                    />

                    {{ pharmacy.distance }}km
                  </div>

                  <a
                    :href="`https://www.google.com/maps/search/?api=1&query=${pharmacy.coords.lat},${pharmacy.coords.long}`"
                    target="_blank"
                    class="pharmacy-card__map"
                  >
                    ver mapa
                  </a>
                </div>
              </div>

              <div
                v-if="showLoadMore"
                class="w-full flex flex-row items-center justify-center"
              >
                <div class="max-w-full w-64">
                  <ui-button
                    color="primary"
                    :disabled="ui.isLoading"
                    label="Carregar mais"
                    @click="goToNextPage"
                  />
                </div>
              </div>
            </div>
          </transition>

          <transition
            name="fade"
            mode="out-in"
          >
            <p
              v-if="ui.hasLoaded && !ui.isLoading && pharmaciesList.length === 0"
              class="mt-8 text-gray-400"
            >
              Não foram encontradas farmácias próximas a você.
            </p>
          </transition>

          <transition
            name="fade"
            mode="out-in"
          >
            <card
              v-if="ui.showCard"
              @close="ui.showCard = false"
            />
          </transition>

          <transition
            name="fade"
            mode="out-in"
          >
            <ui-loader v-if="ui.isLoading" />
          </transition>
        </article>
      </template>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'vue-debounce';
import { Loader } from '@googlemaps/js-api-loader';
import address from '@/api/appointmentScheduling/address';
import pharmacyDiscount from '@/api/user/pharmacyDiscount';
import Card from '@/components/user/pharmacyDiscount/card.vue';
import UiButton from '@/components/ui/Button.vue';
import Icon from '@/components/ui/Icon.vue';
import UiLoader from '@/components/ui/Loader.vue';

export default {
  name: 'PharmacyList',

  components: {
    Card,
    Icon,
    UiButton,
    UiLoader,
  },

  data() {
    return {
      pharmacyDiscount: null,
      autocompleteAddress: [],
      address: null,
      latitude: null,
      longitude: null,
      service: null,
      pharmaciesList: [],
      pagination: {
        total: 0,
        current: 0,
      },
      ui: {
        hasLoaded: false,
        isLoading: false,
        showCard: false,
        step: 1,
      },
    };
  },

  computed: {
    ...mapGetters('user', ['headers']),

    showLoadMore() {
      return this.pagination.current < this.pagination.total - 1;
    },
  },

  watch: {
    address: {
      deep: false,
      immediate: false,
      handler(newValue, oldValue) {
        if (
          newValue
          && newValue !== oldValue
          && !this.autocompleteAddress.some((item) => item.address === this.address)
        ) {
          this.address = this.maskCep(this.address);
          this.searchAddress();
        }

        if (newValue === '' || newValue === null) {
          this.autocompleteAddress = [];
          this.address = null;
          this.latitude = null;
          this.longitude = null;
        }
      },
    },

    'ui.step': {
      deep: false,
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.resetPharmaciesList();
        }
      },
    },
  },

  created() {
    const loader = new Loader({
      apiKey: process.env.VUE_APP_GOOGLE_MAPS,
      libraries: ['places'],
      language: 'pt-BR',
    });

    loader.load()
      .then(() => {
        // eslint-disable-next-line no-undef
        this.service = new google.maps.places.AutocompleteService();
      })
      .catch(console.log);

    this.pharmacyDiscount = pharmacyDiscount(this.headers);
    this.resetPharmaciesList();
  },

  destroyed() {
    this.resetPharmaciesList();
  },

  methods: {
    ...mapActions('ui', ['toggleProgressBar', 'openModalDialog', 'closeModalDialog']),

    searchAddress: debounce(function () {
      if (this.address !== null && this.address !== '') {
        this.service.getQueryPredictions({
          input: this.address,
          types: ['(address)'],
          componentRestrictions: { country: 'br' },
        }, this.showSuggestions);
      }
    }, 1000),

    showSuggestions(predictions) {
      if (predictions) {
        this.autocompleteAddress = [];

        predictions.forEach((prediction) => {
          const { 'description': address, 'place_id': placeId } = prediction;

          // eslint-disable-next-line no-undef
          const geocoder = new google.maps.Geocoder();
          geocoder.geocode({ placeId }, (results, status) => {
            // eslint-disable-next-line no-undef
            if (status === google.maps.GeocoderStatus.OK) {
              const latitude = results[0].geometry.location.lat();
              const longitude = results[0].geometry.location.lng();

              this.autocompleteAddress.push({
                address,
                latitude,
                longitude,
              });
            }
          });
        });

        return;
      }

      this.openModalDialog({
        type: 'alert',
        title: 'Erro',
        text: 'CEP ou endereço não encontrado.',
        confirmText: 'Fechar',
        size: 'sm',
        fn: this.closeModalDialog,
      });
    },

    setAddress(address) {
      this.address = address.address;
      this.latitude = address.latitude;
      this.longitude = address.longitude;
      this.autocompleteAddress = [];
    },

    getGeolocation() {
      const { geolocation } = navigator;

      geolocation.getCurrentPosition((data) => {
        const localAddress = address;
        const { latitude, longitude } = data.coords;

        this.toggleProgressBar();

        localAddress({ latitude, longitude })
          .getMapAddress()
          .then((address) => {
            this.autocompleteAddress = [address];
          })
          .finally(this.toggleProgressBar(false));
      });
    },

    goToStep(step) {
      this.$emit('change-step', step);
      this.ui.step = step;

      if (step === 2) {
        this.findPharmacies();
      }
    },

    findPharmacies() {
      this.toggleProgressBar();
      this.ui.isLoading = true;

      this.pharmacyDiscount
        .findNearbyPharmacies(
          { latitude: this.latitude, longitude: this.longitude },
          this.pagination.current,
        )
        .then((res) => {
          this.pagination.total = res.pages;
          this.pharmaciesList = this.pharmaciesList.concat(res.data);
        })
        .catch(console.log)
        .finally(() => {
          this.ui.isLoading = false;
          this.ui.hasLoaded = true;
          this.toggleProgressBar(false);
        });
    },

    goToNextPage() {
      this.pagination.current += 1;
      this.findPharmacies();
    },

    maskCep(address = null) {
      const matches = String(address).match(/(\d{8})/g) || [];

      if (address && matches.length > 0) {
        let newAddress = address;

        const codes = matches.map((item) => {
          const masked = item.replace(/^([\d]{5})([\d]{3})?/g, '$1-$2');

          return { entry: item, masked };
        });

        codes.forEach((code) => {
          newAddress = newAddress.replace(code.entry, code.masked);
        });

        return newAddress;
      }

      return address;
    },

    resetPharmaciesList() {
      this.pharmaciesList = [];
      this.pagination = { total: 0, current: 0 };
    },
  },
};
</script>
