<template>
  <div class="overlay">
    <div class="overlay-wrapper">
      <i
        class="icon icon-cross text-white my-3 mx-2 cursor-pointer"
        @click="$emit('close', true)"
      />

      <div class="card">
        <div class="card__identifier">
          <span>identificador:</span>

          <span>DRCONECTA</span>
        </div>
        <div class="card__number">
          {{ user.cpf | toNumber }}
        </div>

        <div class="card__holder">
          {{ user.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PharmacyDiscountCard',

  filters: {
    toNumber: (str) => {
      if (str) {
        return String(str)
          .replace(/\.-/gi, '')
          .replace(/^([\d]{3})([\d]{3})([\d]{3})([\d]{2})$/gi, '$1 $2 $3 $4');
      }

      return '';
    },
  },

  computed: {
    ...mapGetters('partner', ['partner']),

    ...mapGetters('user', ['user']),
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  @apply fixed inset-0 h-screen w-screen flex justify-center items-center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999999;

  &-wrapper {
    @apply flex flex-col justify-end items-end;
    max-width: 400px;
    width: 100%;
  }
}

.card {
  @apply w-full flex flex-col items-start justify-end p-8;
  border-radius: 1.2rem;
  height: calc(400px * 0.59);
  background-image: url('../../../assets/img/user/pharmacy-card.png');
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 400px;

  &__identifier {
    @apply mb-3;
    color: #ffffff;

    span {
      &:first-of-type {
        @apply mr-2 text-sm;
        color: rgb(206, 235, 248);
      }

      &:last-of-type {
        @apply font-semibold;
      }
    }
  }

  &__img {
    background: #ffffff;
    border-radius: 5px;
    height: auto;
    max-width: 150px;
    padding: 5px;
    width: 100%;
  }

  &__number {
    @apply text-3xl text-white font-medium;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  }

  &__holder {
    @apply text-xl text-white;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  }
}
</style>
