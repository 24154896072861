<template>
  <div>
    <!-- User does not have discount -->
    <div v-if="!ui.isLoading && !ui.hasDiscount && ui.step === 1">
      <article class="user-section view sm:!mr-0 sm:!ml-0 !mt-0 !pt-0">
        <h1 class="text-secondary flex flex-row items-center justify-center pt-6">
          <icon
            name="discountPharmacy"
            color="secondary"
            fill="secondary"
            class="h-16 mt-3 mr-3"
          />
          Desconto Farmácia
        </h1>

        <p class="text-center text-gray-400 mt-0">
          Ao agendar uma consulta, você ativa <strong>descontos de até 80%</strong>
          <br>
          para medicamentos selecionados em farmácias credenciadas por todo o Brasil.<sup>*</sup>
        </p>

        <p
          class="
            text text-blue
            mt-5 font-semibold
            bg-gray-50 py-1 px-4 border
            border-dashed border-gray-300"
        >
          Agende sua consulta e tenha acesso a descontos exclusivos em farmácias parceiras.
        </p>

        <router-link
          :to="{ name: 'UserScheduling' }"
          class="button button--primary mt-8 w-64 max-w-full"
        >
          Agendar consulta
        </router-link>
      </article>

      <p class="mt-0 text-xs text-right italic text-gray-400">
        <sup>*</sup> validade de 30 dias a partir da data da consulta
      </p>
    </div>

    <!-- User has discount -->
    <div v-if="!ui.isLoading && ui.hasDiscount">
      <article
        v-if="ui.step === 1"
        class="user-section view sm:!mr-0 sm:!ml-0 !mt-0 !pt-0"
      >
        <h1 class="text-secondary flex flex-row items-center justify-center pt-6">
          <icon
            name="discountPharmacy"
            color="secondary"
            fill="secondary"
            class="h-16 mt-3 mr-3"
          />
          Desconto Farmácia
        </h1>

        <p class="text-center text-gray-400 mt-0">
          {{ user.name }}, você conta <strong>descontos de até 80%</strong> para medicamentos
          selecionados
          <br>
          em farmácias credenciadas por todo o Brasil, com validade até
          <strong>{{ dueDate }}</strong>.
        </p>

        <p class="text-center text-primary mt-8 mb-12">
          Clique no botão abaixo para exibir seu
          <span class="font-bold">
            Cartão Virtual Dr. Conecta
          </span>
          e
          <br>
          apresente na farmácia para obter seu desconto.
        </p>

        <div class="max-w-full w-64">
          <ui-button
            color="secondary"
            :disabled="false"
            label="Meu cartão de descontos"
            @click="ui.showCard = true"
          />
        </div>

        <transition
          name="fade"
          mode="out-in"
        >
          <card
            v-if="ui.showCard"
            @close="ui.showCard = false"
          />
        </transition>
      </article>

      <pharmacy-list @change-step="(step) => ui.step = step" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { parseISO, format } from 'date-fns';
import pharmacyDiscount from '@/api/user/pharmacyDiscount';
import handleError from '@/mixins/handleError';
import Icon from '@/components/ui/Icon.vue';
import UiButton from '@/components/ui/Button.vue';
import Card from '@/components/user/pharmacyDiscount/card.vue';
import PharmacyList from '@/components/user/pharmacyDiscount/pharmacyList.vue';

export default {
  name: 'PharmacyDiscount',

  mixins: [handleError],

  components: {
    Card,
    Icon,
    UiButton,
    PharmacyList,
  },

  data() {
    return {
      ui: {
        isLoading: false,
        hasDiscount: false,
        showCard: false,
        step: 0,
      },
      dueDate: null,
      pharmacyDiscount: null,
    };
  },

  computed: {
    ...mapGetters('partner', ['partner']),

    ...mapGetters('user', ['user', 'headers']),
  },

  created() {
    if (!this.partner.hasIntegrationPartners) {
      this.$router.push({ name: 'UserAccount' });
      return;
    }

    this.pharmacyDiscount = pharmacyDiscount(this.headers);

    this.init();
  },

  methods: {
    ...mapActions('ui', ['toggleProgressBar']),

    init() {
      this.ui.isLoading = false;
      this.toggleProgressBar();

      this.pharmacyDiscount
        .getDiscountDueDate()
        .then((res) => {
          this.dueDate = this.formatDate(res);
          this.ui.hasDiscount = true;
          this.ui.step = 1;
        })
        .catch(() => {
          this.ui.hasDiscount = false;
          this.ui.step = 1;
        })
        .finally(() => {
          this.ui.isLoading = false;
          this.toggleProgressBar(false);
        });
    },

    formatDate(date) {
      return format(parseISO(date), 'dd/MM/yyyy');
    },
  },
};
</script>
